import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { PlatformVersionDto } from "../generated";

interface VersionSelectProps {
  versions?: PlatformVersionDto[];
  onChange: any;
}

const VersionSelect: React.FC<VersionSelectProps> = ({
  versions,
  onChange,
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Platform Version</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label="Platform Version"
        onChange={onChange}
        defaultValue=""
      >
        {versions?.map((versions) => (
          <MenuItem key={versions.id} value={versions.id}>
            {versions.id}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default VersionSelect;
