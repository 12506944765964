import { Button, Dialog, TextField, Toolbar, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { PlatformProps } from "./Platforms";
import VersionSelect from "./VersionSelect";
import axiosInstance from "../axios";
import { PlatformVersionDto, VersionsService } from "../generated";

interface PlatformBulkActionsFooterProps {
  selected: PlatformProps[];
}

const PlatformBulkActionsFooter: React.FC<PlatformBulkActionsFooterProps> = ({
  selected,
}) => {
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  return (
    <Toolbar
      sx={{
        position: "sticky",
        bottom: 0,
        opacity: 1,
        justifyContent: "space-between",
        bgcolor: "background.paper",
      }}
    >
      <Button onClick={handleOpen1}>Version</Button>
      <EditVersionModal
        selected={selected}
        open={open1}
        onClose={handleClose1}
      />
      selected {selected.length}
      <EditCsmModal selected={selected} open={open2} onClose={handleClose2} />
      <Button onClick={handleOpen2}>CSM</Button>
    </Toolbar>
  );
};

export default PlatformBulkActionsFooter;

interface EditVersionModalProps {
  selected: PlatformProps[];
  open: boolean;
  onClose: () => void;
}

const EditVersionModal: React.FC<EditVersionModalProps> = ({
  selected,
  open,
  onClose,
}) => {
  const [version, setVersion] = useState<string>();
  const handleVersionChange = (version?: string) => {
    setVersion(version);
  };

  const [versions, setVersions] = useState<PlatformVersionDto[]>();
  useEffect(() => {
    VersionsService.infraListVersionsInfraVersionsGet().then((versions) => {
      setVersions(versions);
    });
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <Toolbar>
        <Typography>
          <p>Bulk edit version</p>
        </Typography>
      </Toolbar>
      <>
        <Typography>Choose your version.</Typography>
        <VersionSelect versions={versions} onChange={handleVersionChange} />
        <p>Review your selection:</p>
        <ul>
          {selected?.map((platform, index) => {
            return <li key={index}>{platform.name}</li>;
          })}
        </ul>
      </>
      <Toolbar>
        <Button onClick={onClose}>Cancel</Button>
        {version && <Button onClick={() => handleVersionChange}>Save</Button>}
      </Toolbar>
    </Dialog>
  );
};

interface EditCsmModalProps {
  selected: PlatformProps[];
  open: boolean;
  onClose: () => void;
}

const EditCsmModal: React.FunctionComponent<EditCsmModalProps> = ({
  selected,
  open,
  onClose,
}) => {
  const [text, setText] = useState<string>("");
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    const items = selected.map((platform) => {
      return {
        id: platform.id,
        name: platform.name,
        hostname: platform.sites,
        date_created: platform.date_created,
        slack_channel_name: platform.slack_channel_name,
        slack_channel_topic: platform.slack_channel_topic,
        slack_channel_description: platform.slack_channel_description,
        csm: text,
        version: platform.version,
        scale: platform.scale,
        user_pool_id: platform.user_pool_id,
        cognito_user_pool_domain: platform.cognito_user_pool_domain,
        cognito_client_id: platform.cognito_client_id,
      };
    });
    const init = {
      body: {
        items,
      },
    };

    axiosInstance
      .put(`/platforms`, init)
      .then(function (response: any) {
        console.log(JSON.stringify(response, null, 4));
        enqueueSnackbar("CSM updated", { variant: "success" });
        console.log(response);
      })
      .catch(function (error: { response: any }) {
        enqueueSnackbar("CSM updated", { variant: "error" });
        console.log(error.response);
      })
      .then(function () {
        // always executed
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Toolbar>
        <Typography>Edit CSM</Typography>
      </Toolbar>

      <>
        <TextField value={text} onChange={handleChange} label="csm" />

        <>
          <p>Review your selection:</p>
          <ul>
            {selected?.map((platform, index) => {
              return <li key={index}>{platform.name}</li>;
            })}
          </ul>
        </>
      </>

      <Toolbar>
        <Button onClick={onClose}>Cancel</Button>

        {text && (
          <Button
            onClick={() => {
              handleSubmit();
              onClose();
            }}
          >
            Save
          </Button>
        )}
      </Toolbar>
    </Dialog>
  );
};
