import axios from "axios";
import {aws4Interceptor} from "aws4-axios";

const AWS = require('aws-sdk');
const myConfig = new AWS.Config({
    region: 'us-east-2'
});
AWS.config.update(myConfig);

// @ts-ignore
const credentials = JSON.parse(localStorage.getItem('credentials'));
const interceptor = aws4Interceptor({
    region: "us-east-2",
    service: "execute-api",
    signQuery: true
}, credentials);

if (window.location.hostname != 'localhost') {
    axios.interceptors.request.use(interceptor);
}


export function getIAMAccessToken(identityPoolId: string, userPool: string) {
    
    // Add the User's Id Token to the Cognito credentials login map.
    let credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: identityPoolId,
        Logins: {[`cognito-idp.us-east-2.amazonaws.com/${userPool}`] : localStorage.getItem('id_token')}
    });
    
    //call refresh method in order to authenticate user and get new temp credentials
    credentials.get((error: any) => {

        if (error) {
            console.error(error);

            let response = {
                statusCode: 500,
                body: JSON.stringify(error)
            };

        } else {
            console.log(credentials)
            localStorage.setItem('expire_time', credentials.expireTime.toISOString())
            
            localStorage.setItem('credentials', JSON.stringify({
                secretAccessKey: credentials.secretAccessKey,
                accessKeyId: credentials.accessKeyId,
                sessionToken: credentials.sessionToken
            }));

            let response = {
                statusCode: 200,
                body: JSON.stringify({
                    'AKI': credentials.accessKeyId,
                    'AKS': credentials.secretAccessKey,
                    'token': credentials.sessionToken
                })
            };
        }
    });
}

export const findIdToken = () => {
    const parts = window.location.hash.split(/[\#\=\&]/);
    for (let i = 0; i < parts.length; i++) {
        if (parts[i] === "id_token") {
            localStorage.setItem("id_token", parts[i + 1]);
            return parts[i + 1];
        }
    }
};

export const isTokenExpired = () => {
    const expire_time = localStorage.getItem('expire_time');
    if (expire_time) {
        return (Date.parse(expire_time) - Date.now()) < 0;
    }
    return true
}