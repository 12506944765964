import { Grid, Toolbar, Typography } from "@mui/material";
import { useState } from "react";
import ClientSelector from "./ClientSelector";
import DataGrid from "./DataGrid";
import Papaparse from "./Papaparse";
import Validation from "./Validation";

interface ImporterProps {}
interface DataProps {
  data: any;
  errors: any;
  meta: any;
}

const Importer: React.FC<ImporterProps> = () => {
  const [state, setState] = useState<DataProps>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errors, setErrors] = useState<any>();

  const handleState = (newState: any) => {
    setState(newState);
  };

  const handleErrors = (newErrors: any) => {
    setErrors(newErrors);
  };

  return (
    <>
      <>
        <>
          <Toolbar />
          <Toolbar>
            <Typography>Title</Typography>
          </Toolbar>
        </>
      </>
      <Grid container>
        <Grid item>
          <ClientSelector />
        </Grid>

        <Grid item xs={12}>
          <Papaparse handleState={handleState} />
        </Grid>
        {state?.data && (
          <>
            <Grid item xs={12}>
              <DataGrid rows={state.data} handleState={handleState} />
            </Grid>
            <Grid item xs={12}>
              <Validation handleErrors={handleErrors} data={state.data} />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default Importer;
