import { CircularProgress } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { TagProps } from "../models/email";
import axiosInstance from "../axios";

interface TagFilterProps {
  formik: any;
  name: string;
}

const TagFilter: React.FC<TagFilterProps> = ({ formik, name }) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<TagProps[]>([]);
  const loading = open && options.length === 0;

  //get tags from API
  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

      axiosInstance.get(`/email/tags/email-testing`)
          .then(function (response: any) {
              // handle success
              console.log(response);
              console.log(`GET: ${JSON.stringify(response, null, 4)}`);

              const updatedTags = response.map((tag: TagProps) => {
                  return {
                      ...tag,
                      groupHeader: tag.type ? tag.type : "unknown tag type",
                  };
              });
              updatedTags.sort(
                  (a: TagProps, b: TagProps) =>
                      // @ts-ignore
                      -b.groupHeader.localeCompare(a.groupHeader)
              );
              if (active) {
                  setOptions(updatedTags);
              }
          })
          .catch(function (error: any) {
              // handle error
              console.log(error);
          })
          .then(function () {
              // always executed
          });

    return () => {
      active = false;
    };
  }, [loading]);

  return (
    <Autocomplete
      fullWidth
      multiple
      value={formik.values[name]}
      onChange={(event: any, newValue: string[] | null) => {
        formik.setFieldValue(name, newValue);
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      loading={loading}
      options={options}
      groupBy={(option) => option.groupHeader}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={name}
          placeholder="filter by tags"
          name={name}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      isOptionEqualToValue={(option, value) =>
        option.name === value.name && option.tagType === value.tagType
      }
    />
  );
};

export default TagFilter;
