import React, { useRef } from "react";
import EmailEditor from "react-email-editor";

interface PeterProps {}

// const apiName = "platforms"; // replace this with your api name.
// const path1 = "/platforms/value3"; //replace this with the path you have configured on your API
// const path2 = "/platforms";
// const myInit = {
//   body: { name: "peter" }, // replace this with attributes you need
//   headers: {}, // OPTIONAL
// };

// API.post(apiName, path1, myInit)
//   .then((response) => {
//     // Add your code here
//     console.log(`POST: ${JSON.stringify(response, null, 4)}`);
//   })
//   .catch((error) => {
//     console.log(error.response);
//   });

// API.get("platforms", "/sites/hostname3x", null)
//   .then((response) => {
//     // Add your code here
//     console.log(`GET: ${JSON.stringify(response, null, 4)}`);
//   })
//   .catch((error) => {
//     console.log(error.response);
//   });

// const apiName = "platforms"; // replace this with your api name.
// const path = "/platforms/value1"; // replace this with the path you have configured on your API
// const myInit = {
//   body: {
//     id: "value1",
//     name: "peter",
//     hostnames: ["peter.com"],
//   }, // replace this with attributes you need
//   headers: {}, // OPTIONAL
// };

// API.put(apiName, path, myInit)
//   .then((response) => {
//     // Add your code here
//     console.log(JSON.stringify(response, null, 4));
//   })
//   .catch((error) => {
//     console.log(error.response);
//   });

const Peter: React.FC<PeterProps> = () => {
  const emailEditorRef = useRef<any>(null);

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { design, html } = data;
      console.log("exportHtml", html);
    });
  };

  const onLoad = () => {
    // editor instance is created
    // you can load your template here;
    // const templateJson = {};
    // emailEditorRef.current.editor.loadDesign(templateJson);
  };

  const onReady = () => {
    // editor is ready
    console.log("onReady");
  };

  return (
    <div>
      <div>
        <button onClick={exportHtml}>Export HTML</button>
      </div>

      <EmailEditor ref={emailEditorRef} onLoad={onLoad} onReady={onReady} />
    </div>
  );
};

export default Peter;
