import * as React from "react";
import { useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { PlatformDto, PlatformsService } from "../generated";
import PlatformList from "./PlatformList";
import PlatformSingle from "./PlatformSingle";
import { PlatformSingleNew } from "./PlatformSingleForm";

interface PlatformsProps {}

export interface PlatformProps extends PlatformDto {
  isSelected?: boolean;
}

const Platforms: React.FC<PlatformsProps> = () => {
  let navigate = useNavigate();
  const [platforms, setPlatforms] = useState<PlatformProps[]>();
  const [platform, setPlatform] = useState<PlatformProps>();

  const handlePlatformClick = (platform: PlatformProps) => {
    setPlatform(platform);
    navigate(platform.id);
  };

  React.useEffect(() => {
    handleFetchPlatforms();
  }, []);

  const handleFetchPlatforms = async () => {
    try {
      PlatformsService.platformsGetPlatformsGet().then((res) => {
        const platforms_props: PlatformProps[] = res.map((platform) => {
          return { ...platform, isSelected: false } as PlatformProps;
        });
        setPlatforms(platforms_props);
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PlatformList
            platforms={platforms}
            handlePlatformClick={handlePlatformClick}
          />
        }
      />
      <Route
        path={`:platformId`}
        element={<PlatformSingle platform={platform} />}
      />
      <Route path={`new-platform`} element={<PlatformSingleNew />} />
    </Routes>
  );
};

export default Platforms;
