import { AppBar, Box, Button, Toolbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Menu from "./components/Menu";
import Clients from "./email/Clients";
import Importer from "./importer/Importer";
import Peter from "./platforms/Peter";
import Platforms from "./platforms/Platforms";
import ToggleColorMode from "./theme/ToggleColorMode";
import { SnackbarProvider } from "notistack";
import { OpenAPI } from "./generated";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { findIdToken, getIAMAccessToken, isTokenExpired } from "./auth";

const App: React.FC = () => {
  OpenAPI.BASE = process.env.REACT_APP_API_URL || "http://localhost:8000";

  // If there is an id_token from our cognito, we need to store it.
  const idToken = findIdToken();

  const [isSignedIn, setIsSignedIn] = useState(!isTokenExpired());

  useEffect(() => {
    if (localStorage.getItem("id_token") || isSignedIn) {
      getIAMAccessToken(
        "us-east-2:3c1c6f17-1000-4173-9541-efbe370ff6fe",
        "us-east-2_ep6pfsFSL"
      );
      setIsSignedIn(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idToken]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    // There is certainly a better way to do this...
    const hostname_parts = window.location.href.split("/");
    const redirect = hostname_parts[0] + "//" + hostname_parts[2];
    window.open(
      `https://mission-control-dev.auth.us-east-2.amazoncognito.com/login?client_id=2m7vu5dsda5l38dd3ehi8kkl57&response_type=token&scope=email+openid+profile&redirect_uri=${redirect}`
    );
  };

  const theme = createTheme();

  if (isSignedIn) {
    return (
      <ToggleColorMode>
        <SnackbarProvider>
          <Box sx={{ display: "flex" }}>
            <AppBar
              position="fixed"
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
              <Toolbar>
                <Typography variant="h6" noWrap component="div">
                  Mission Control
                </Typography>
              </Toolbar>
            </AppBar>
            <BrowserRouter>
              <Menu />
              <Box
                sx={{
                  flex: 1,
                }}
              >
                <Routes>
                  <Route path="/platforms/*" element={<Platforms />} />
                  <Route path="/email/*" element={<Clients />} />
                  <Route path="/importer" element={<Importer />} />
                  <Route path="/peter" element={<Peter />} />
                </Routes>
              </Box>
            </BrowserRouter>
          </Box>
        </SnackbarProvider>
      </ToggleColorMode>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              Juno Mission Control
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign in
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    );
  }
};

export default App;
