import { DataGrid as DG, GridColDef, GridToolbar } from "@mui/x-data-grid";
import * as React from "react";

interface DataGridProps {
  rows: any[];
  handleState: (newState: any) => void;
}

const DataGrid: React.FC<DataGridProps> = ({ rows, handleState }) => {
  const columns: GridColDef[] = Object.keys(rows[0]).map((key) => ({
    field: key,
    headerName: key,
    width: 130,
    editable: true,
  }));

  return (
    <div style={{ width: "100%" }}>
      <DG
        rows={rows}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[5, 20]}
        getRowId={(row) => row.id}
        components={{ Toolbar: GridToolbar }}
        autoHeight
        onStateChange={(state, event, details) => console.log(state)}
      />
    </div>
  );
};

export default DataGrid;
