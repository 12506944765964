import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { PlatformDto, PlatformsService } from "../generated";
import { useSnackbar } from "notistack";

interface PlatfromListFooterProps {
  count?: number;
}

const PlatfromListFooter: React.FC<PlatfromListFooterProps> = ({ count }) => {
  let navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [newPlatformName, setNewPlatformName] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  const isSiteUpYet = async (url: string) => {
    await delay(5000);

    return fetch(`https://${url}`, {
      method: "HEAD",
    })
      .then(async (response) => {
        if (response.ok) {
          enqueueSnackbar(`Site is ready: ${url}`, {
            variant: "success",
            persist: true,
          });
          navigate(`platforms`);
          return true;
        } else {
          console.log("Platform not ready yet...trying again..");
          await isSiteUpYet(url);
          return false;
        }
      })
      .catch(async (err) => {
        console.log(err);
        enqueueSnackbar(
          `Almost there! Waiting on CDN for: ${newPlatformName}`,
          { variant: "info", persist: true, preventDuplicate: true }
        );
        await isSiteUpYet(url);
      });
  };

  const handleCreatePlatform = () => {
    setOpen(false);
    enqueueSnackbar(`Creating platform: ${newPlatformName}`, {
      variant: "info",
      persist: true,
    });
    PlatformsService.platformCreatePlatformsPost({
      id: newPlatformName,
      name: newPlatformName,
    }).then((res: PlatformDto[]) => {
      console.log(res);
      navigate(`/platforms`);
      console.log('yo!!!')
      console.log(res[0])
      isSiteUpYet(res[0]?.sites?.[0]!);
    });
  };

  return (
    <Stack
      direction="row"
      sx={{
        position: "sticky",
        bottom: 0,
        opacity: 1,
        justifyContent: "space-between",
        bgcolor: "background.paper",
        zIndex: 1,
      }}
    >
      <Toolbar>
        <Button variant="outlined" onClick={handleClickOpen}>
          Create New Platform
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Create New Juno Platform</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="new-juno-platform-name"
              label="Enter Platform Name..."
              type="text"
              fullWidth
              variant="standard"
              onChange={(e) => {
                setNewPlatformName(e.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleCreatePlatform}>Create</Button>
          </DialogActions>
        </Dialog>
      </Toolbar>
      <Toolbar>
        <Typography slot="end" style={{ paddingRight: "16px" }}>
          {count ? `showing ${count}` : "loading..."}
        </Typography>
      </Toolbar>
    </Stack>
  );
};

export default PlatfromListFooter;
