/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PlatformVersionDto } from '../models/PlatformVersionDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VersionsService {

    /**
     * Infra List Versions
     * @returns PlatformVersionDto Successful Response
     * @throws ApiError
     */
    public static infraListVersionsInfraVersionsGet(): CancelablePromise<Array<PlatformVersionDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/infra/versions/',
        });
    }

}