import { Box, Skeleton, Stack } from "@mui/material";
import { ReactElement } from "react";

interface SkeletonPropsProps {
  loop: number;
  variant: "platforms" | "clients" | "campaigns";
}

export const SkeletonProps: React.FC<SkeletonPropsProps> = ({
  loop,
  variant,
}) => {
  const SkeletonItem = (): ReactElement => {
    switch (variant) {
      case "platforms":
        return <PlatformSkeleton />;
      case "clients":
        return <ClientSkeleton />;
      case "campaigns":
        return <CampaignSkeleton />;
      default:
        return <ClientSkeleton />;
    }
  };
  return (
    <>
      {[...Array(loop)].map((_, index) => (
        <SkeletonItem key={index} />
      ))}
    </>
  );
};

export default SkeletonProps;

interface PlatformSkeletonProps {}

const PlatformSkeleton: React.FC<PlatformSkeletonProps> = () => {
  return (
    <Box sx={{ padding: "24px 24px", display: "flex" }}>
      <Stack spacing={2}>
        <Skeleton variant="rectangular" width={210} height={20} />
        <Skeleton variant="rectangular" width={300} height={10} />
      </Stack>
      <Skeleton
        variant="circular"
        sx={{ marginLeft: "auto" }}
        width={32}
        height={32}
      />
    </Box>
  );
};

interface ClientSkeletonProps {}

const ClientSkeleton: React.FC<ClientSkeletonProps> = () => {
  return (
    <Box sx={{ padding: "12px 24px", display: "flex" }}>
      <Stack spacing={2}>
        <Skeleton variant="rectangular" width={210} height={20} />
      </Stack>
    </Box>
  );
};

interface CampaignSkeletonProps {}

const CampaignSkeleton: React.FC<CampaignSkeletonProps> = () => {
  return (
    <Box sx={{ padding: "24px 24px", display: "flex" }}>
      <Stack spacing={2}>
        <Skeleton variant="rectangular" width={210} height={20} />

        <Skeleton variant="rectangular" width={300} height={10} />
      </Stack>
      <Skeleton
        variant="circular"
        sx={{ marginLeft: "auto" }}
        width={32}
        height={32}
      />
    </Box>
  );
};
