import { ListItem, ListItemText } from "@mui/material";
import { EmailProps } from "../models/email";

interface CampaignItemProps {
  campaign: EmailProps;
  handleItemClick: (campaign: any) => void;
}

const CampaignItem: React.FC<CampaignItemProps> = ({
  campaign,
  handleItemClick,
}) => {
  return (
    <ListItem onClick={() => handleItemClick(campaign)} button>
      <ListItemText>{campaign.campaign_name}</ListItemText>
    </ListItem>
  );
};

export default CampaignItem;
