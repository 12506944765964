import { Toolbar } from "@mui/material";
import { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { EmailProps } from "../models/email";
import { PlatformProps } from "../platforms/Platforms";
import Campaign from "./Campaign";
import CampaignList from "./CampaignList";
import NewCampaign from "./NewCampaign";
import axiosInstance from "../axios";

interface CampaignProps {
  client?: PlatformProps;
}

const Campaigns: React.FC<CampaignProps> = ({ client }) => {
  const { clientid } = useParams<{ clientid: string }>();
  let navigate = useNavigate();
  const [campaigns, setCampaigns] = useState<EmailProps[]>();
  const [campaign, setCampaign] = useState<EmailProps>();
  const [isError, setIsError] = useState(false);

  const handleItemClick = (c: EmailProps) => {
    setCampaign(c);
    //TODO this needs to be a campaign id
    navigate(`${c.campaign_name}`);
  };
  console.log(clientid);
  useEffect(() => {
    axiosInstance
      .get(`/email/campaigns/${clientid}`)
      .then(function (response: any) {
        // handle success
        console.log(response);
        console.log(`GET: ${JSON.stringify(response, null, 4)}`);
        setCampaigns(response);
      })
      .catch(function (error: any) {
        // handle error
        console.log(error);
        setIsError(true);
      });
  });

  if (isError)
    return (
      <>
        <Toolbar />
        <div>Error. Could not find campaigns for this client.</div>
      </>
    );
  if (!campaigns)
    return (
      <>
        <Toolbar />
        <div>Loading...</div>
      </>
    );

  return (
    <Routes>
      <Route
        path="/"
        element={
          <CampaignList
            campaigns={campaigns}
            client={client}
            handleItemClick={handleItemClick}
          />
        }
      />
      <Route
        path=":campaignid"
        element={<Campaign campaign={campaign} client={client} />}
      />
      <Route path="new-campaign" element={<NewCampaign />} />
    </Routes>
  );
};

export default Campaigns;
