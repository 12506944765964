import DTP from "@mui/lab/DateTimePicker";
import TextField from "@mui/material/TextField";

interface DateTimePickerProps {
  formik: any;
  name: string;
  label?: string;
  disabled?: boolean;
}

const DateTimePicker: React.FC<DateTimePickerProps> = ({
  formik,
  name,
  label,
  disabled,
}) => {
  return (
    <DTP
      clearable
      disabled={disabled}
      //   minDate={minDate}
      //   maxDate={maxDate}
      label={label || name}
      value={formik.values[name]}
      onChange={(value) => formik.setFieldValue(name, value, true)}
      renderInput={(inputProps) => (
        <TextField
          name={name}
          {...inputProps}
          error={formik.touched[name] && Boolean(formik.errors[name])}
          helperText={formik.touched[name] && formik.errors[name]}
          onBlur={() => formik.setFieldTouched(name, true, true)}
        />
      )}
    />
  );
};

export default DateTimePicker;
