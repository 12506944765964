/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PlatformDto } from '../models/PlatformDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PlatformsService {

    /**
     * Platforms Get
     * @returns PlatformDto Successful Response
     * @throws ApiError
     */
    public static platformsGetPlatformsGet(): CancelablePromise<Array<PlatformDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/platforms/',
        });
    }

    /**
     * Platform Create
     * @param requestBody
     * @returns PlatformDto Successful Response
     * @throws ApiError
     */
    public static platformCreatePlatformsPost(
        requestBody: PlatformDto,
    ): CancelablePromise<Array<PlatformDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/platforms/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Platforms Get
     * @param platformId
     * @returns PlatformDto Successful Response
     * @throws ApiError
     */
    public static platformsGetPlatformsPlatformIdGet(
        platformId: string,
    ): CancelablePromise<Array<PlatformDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/platforms/{platform_id}/',
            path: {
                'platform_id': platformId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}