import React, { useRef } from "react";
import EmailEditor from "react-email-editor";

interface DragDropEmailProps {}

const DragDropEmail: React.FC<DragDropEmailProps> = () => {
  const emailEditorRef = useRef<any>(null);

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { design, html } = data;
      console.log("exportHtml", html);
    });
  };

  const onLoad = () => {
    // editor instance is created
    // you can load your template here;
    // const templateJson = {};
    // emailEditorRef.current.editor.loadDesign(templateJson);
  };

  const onReady = () => {
    // editor is ready
    console.log("onReady");
  };

  return (
    <div>
      <div>
        <button onClick={exportHtml}>Export HTML</button>
      </div>

      <EmailEditor ref={emailEditorRef} onLoad={onLoad} onReady={onReady} />
    </div>
  );
};

export default DragDropEmail;
