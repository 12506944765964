import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import axiosInstance from "../axios";
import Skeleton from "../components/Skeleton";
import { PlatformProps } from "../platforms/Platforms";
import Campaigns from "./Campaigns";
interface ClientsProps {}

const Clients: React.FC<ClientsProps> = () => {
  let navigate = useNavigate();

  const [platforms, setPlatforms] = useState<PlatformProps[]>();
  const [platform, setPlatform] = useState<PlatformProps>();

  const handleFetchPlatforms = async () => {
    axiosInstance
      .get(`/platforms`)
      .then(function (response: any) {
        // handle success
        console.log(response);
        setPlatforms(response.data);
      })
      .catch(function (error: any) {
        // handle error
        console.log(error);
      });
  };

  const handleClick = (platform: PlatformProps) => {
    setPlatform(platform);
    navigate(`${platform.name}`);
  };

  useEffect(() => {
    handleFetchPlatforms();
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ClientsList platforms={platforms} handleClick={handleClick} />
        }
      />
      <Route
        path="/:clientid/*"
        element={<ClientSingle platform={platform} />}
      />
    </Routes>
  );
};

export default Clients;

interface ClientsListProps {
  platforms?: PlatformProps[];
  handleClick: (platform: PlatformProps) => void;
}

const ClientsList: React.FC<ClientsListProps> = ({
  platforms,
  handleClick,
}) => {
  const [searchText, setSearchText] = useState<string>("");
  const [filteredPlatforms, setFilteredPlatforms] = useState<PlatformProps[]>();

  //update search state when search text changes
  useEffect(() => {
    if (platforms) {
      const tempFilteredPlatforms: PlatformProps[] = platforms?.filter((p) => {
        return p.name?.toLowerCase().includes(searchText.toLowerCase());
      });
      setFilteredPlatforms(tempFilteredPlatforms);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    if (platforms) {
      setFilteredPlatforms(platforms);
    }
  }, [platforms]);

  return (
    <Box>
      <Box
        className="subheader"
        sx={{
          position: "sticky",
          width: "100%",
          top: 0,
          bgcolor: "background.paper",
          zIndex: 1,
        }}
      >
        <Toolbar />
        <Toolbar>
          <Typography variant="h6">Clients</Typography>
        </Toolbar>
        <Toolbar>
          <TextField
            fullWidth
            value={searchText}
            placeholder="Search"
            onChange={(e) => setSearchText(e.target.value!)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Toolbar>
      </Box>
      <List>
        {platforms ? (
          filteredPlatforms?.map((platform, index) => (
            <ClientItem
              key={index}
              platform={platform}
              handleClick={handleClick}
            />
          ))
        ) : (
          <Skeleton loop={15} variant="clients" />
        )}
      </List>
    </Box>
  );
};

interface ClientItemProps {
  platform: PlatformProps;
  handleClick: (platform: PlatformProps) => void;
}

const ClientItem: React.FC<ClientItemProps> = ({ platform, handleClick }) => {
  return (
    <ListItem
      button
      onClick={() => {
        handleClick(platform);
      }}
      sx={{ paddingLeft: "24px" }}
    >
      {platform?.name}
    </ListItem>
  );
};

interface ClientSingleProps {
  platform?: PlatformProps;
}

const ClientSingle: React.FC<ClientSingleProps> = ({ platform }) => {
  return <Campaigns client={platform} />;
};
