import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import EmailIcon from "@mui/icons-material/Email";
import { Box, Toolbar } from "@mui/material";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import { useContext } from "react";
import { ColorModeContext } from "../theme/ToggleColorMode";
import "./Menu.css";
interface page {
  title: string;
  url: string;
  icon: ReactJSXElement;
}

const pages: page[] = [
  {
    title: "Platforms",
    url: "/platforms",
    icon: <CloudDownloadIcon />,
  },
  {
    title: "Email",
    url: "/email",
    icon: <EmailIcon />,
  },
  {
    title: "Importer",
    url: "/importer",
    icon: <CloudDownloadIcon />,
  },
];

const drawerWidth = 240;

interface MenuProps {}

const Menu: React.FC<MenuProps> = ({ children }) => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        <List>
          {pages.map((page, index) => {
            return (
              <ListItem key={index} button component="a" href={page.url}>
                <ListItemIcon>{page.icon}</ListItemIcon>
                <ListItemText>{page.title}</ListItemText>
              </ListItem>
            );
          })}
        </List>
        <Divider />

        <ListItem button onClick={colorMode.toggleColorMode}>
          <ListItemIcon>
            {theme.palette.mode === "dark" ? (
              <Brightness7Icon />
            ) : (
              <Brightness4Icon />
            )}
          </ListItemIcon>
          <ListItemText>{theme.palette.mode} mode</ListItemText>
        </ListItem>
      </Box>
    </Drawer>
  );
};

export default Menu;
