import { CssBaseline, useMediaQuery } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { createContext, useMemo, useState } from "react";

interface ToggleColorModeProps {}

const getLocalStorage = localStorage.getItem("colorMode");

const ToggleColorMode: React.FC<ToggleColorModeProps> = ({ children }) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const sourceOfTruth =
    getLocalStorage === "light"
      ? "light"
      : "dark" || prefersDarkMode
      ? "dark"
      : "light";
  const [mode, setMode] = useState<"light" | "dark">(sourceOfTruth);
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
        setLocalStorage();
      },
    }),
    [mode]
  );

  const setLocalStorage = () => {
    localStorage.setItem("colorMode", mode === "light" ? "dark" : "light");
  };

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default ToggleColorMode;

export const ColorModeContext = createContext({ toggleColorMode: () => {} });
