import { Box, Grid, List, Toolbar, Typography } from "@mui/material";
import { EmailProps } from "../models/email";
import { PlatformProps } from "../platforms/Platforms";
import Skeleton from "../components/Skeleton";
import CampaignItem from "./CampaignItem";
import CampaignListFooter from "./CampaignListFooter";

interface CampaignListProps {
  campaigns?: any[];
  handleItemClick: (campaign: EmailProps) => void;
  client?: PlatformProps;
}

const CampaignList: React.FC<CampaignListProps> = ({
  campaigns,
  handleItemClick,
  client,
}) => {
  return (
    <Box>
      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          opacity: 1,
          justifyContent: "space-between",
          bgcolor: "background.paper",
          zIndex: 1,
        }}
        className="subheader"
      >
        <Toolbar />
        <Toolbar>
          <Typography variant="h6">Platforms</Typography>
        </Toolbar>
        {/* <Toolbar>
          <TextField
            fullWidth
            value={searchText}
            placeholder="Search"
            onChange={(e) => setSearchText(e.target.value!)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Toolbar> */}
      </Box>
      <List>
        {campaigns ? (
          campaigns?.map((c, index) => (
            <CampaignItem
              key={index}
              handleItemClick={handleItemClick}
              campaign={c}
            />
          ))
        ) : (
          <Skeleton loop={10} variant="campaigns" />
        )}
      </List>
      <CampaignListFooter />
    </Box>
  );
};

export default CampaignList;
