import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import {
  Chip,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { UserProps } from "../models/email";
import { users } from "../models/temp";

interface OutboundModalProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

const OutboundModal: React.FC<OutboundModalProps> = ({
  onClose,
  selectedValue,
  open,
}) => {
  const [searchText, setSearchText] = useState<string>("");
  const [filteredUsers, setFilteredUsers] = useState<UserProps[]>();

  useEffect(() => {
    if (users) {
      const temp: UserProps[] = users?.filter((user) => {
        return user.email?.toLowerCase().includes(searchText.toLowerCase());
      });
      setFilteredUsers(temp);
    }
  }, [searchText]);

  return (
    <Dialog open={open} onClose={onClose}>
      <>
        <Toolbar>
          <DialogTitle>Outbound</DialogTitle>

          {searchText
            ? `filtered: ${filteredUsers?.length} of ${users?.length}`
            : `total: ${users.length}`}
        </Toolbar>
        <Toolbar>
          <TextField
            value={searchText}
            onChange={(e) => setSearchText(e.target.value!)}
          ></TextField>
        </Toolbar>
      </>
      <>
        <List>
          {filteredUsers &&
            filteredUsers?.map((user: UserProps) => (
              <UserItem user={user} key={user.id} />
            ))}
        </List>
      </>
    </Dialog>
  );
};

export default OutboundModal;

interface UserItemProps {
  user: UserProps;
}

const UserItem: React.FC<UserItemProps> = ({ user }) => {
  let icon;
  if (!user?.status?.sent) {
    icon = <HourglassBottomIcon />;
  } else {
    if (user.status.opened) {
      icon = <DoneAllIcon />;
    } else {
      icon = <DoneIcon />;
    }
  }
  return (
    <>
      <ListItem>
        <Typography>{user?.name}</Typography>
        <Typography>
          {user?.email}

          {icon}
        </Typography>
      </ListItem>
      {user?.tags?.length && (
        <ListItem>
          <>
            {user?.tags.map((tag: any) => (
              <Chip label={tag.name} />
            ))}
          </>
        </ListItem>
      )}
    </>
  );
};
