import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Button, InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";
import axiosInstance from "../axios";
interface InputRepeaterProps {
  formik: any;
  name: string;
  sortKeys?: string[];
}

const addFormikValue = (formik: any, name: string, index: number) => {
  const newInput = formik.values[name].concat([]);
  newInput.push("");
  formik.setFieldValue(name, newInput);
};

const removeFormikValue = (formik: any, name: string, index: number) => {
  const newInput = formik.values[name].concat([]);
  newInput.splice(index, 1);
  formik.setFieldValue(name, newInput);
};

const InputRepeater: React.FC<InputRepeaterProps> = ({ formik, name }) => {
  return (
    <>
      {formik.values[name] &&
        formik.values[name].map((n: unknown, index: number) => (
          <TextField
            fullWidth
            key={index}
            name={`${name}[${index}]`}
            value={formik.values[name][index]}
            onChange={formik.handleChange}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            helperText={formik.touched[name] && formik.errors[name]}
            label={name}
            variant="standard"
            inputProps={{
              onBlur: () => formik.setFieldTouched(name, true, true),
            }}
          />
        ))}

      <Button
        onClick={() => addFormikValue(formik, name, formik.values[name].length)}
      >
        Add {name}
      </Button>
      {!!formik.values[name].length && (
        <Button
          slot="end"
          onClick={() =>
            removeFormikValue(formik, name, formik.values[name].length - 1)
          }
        >
          Remove {name}
        </Button>
      )}
    </>
  );
};

export default InputRepeater;

interface SitesRepeaterProps {
  formik: any;
  sortKeys?: string[];
}

const SitesRepeater: React.FC<SitesRepeaterProps> = ({ formik, sortKeys }) => {
  //   const count = formik.values[name].length;
  const handleClick = (index: number) => {
    console.log("clicked");
    deleteInput(index);
  };
  const deleteInput = (index: number) => {
    //if values are actually in db
    if (sortKeys?.includes(formik.values.sites[index])) {
      //if you're deleting the only sort key
      if (sortKeys.length === 1) {
        alert("You can't delete the only sort key");
        return;
      }
      console.log("was in db");

      // @ts-ignore
      axiosInstance
        .delete(`/platforms/${formik.values.id}`, {
          data: {
            id: formik.values.id,
            sites: formik.values.sites[index],
          },
        })
        .then(() => removeFormikValue(formik, "sites", index))
        .catch((err: any) => {
          console.log(err);
        });
    } else {
      console.log("not in db");
      removeFormikValue(formik, "sites", index);
    }
  };

  return (
    <>
      {formik.values.sites &&
        formik.values.sites.map((n: string, index: number) => (
          <TextField
            variant="standard"
            id={`sites-${index}`}
            fullWidth
            key={index}
            name={`sites[${index}]`}
            label="site"
            value={formik.values.sites[index]}
            onChange={formik.handleChange}
            helperText={
              formik.touched.sites &&
              Boolean(formik.errors.sites) &&
              formik.errors.sites[index]
            }
            error={
              formik.touched.sites &&
              Boolean(formik.errors.sites) &&
              typeof formik.errors.sites[index] === "string"
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <DeleteOutlineIcon onClick={() => handleClick(index)} />
                </InputAdornment>
              ),
              onBlur: () => formik.setFieldTouched("sites", true, true),
            }}
            sx={{ padding: "8px 0" }}
          />
        ))}

      {formik.values.sites[formik.values.sites.length - 1] !== "" && (
        <Button
          onClick={() =>
            addFormikValue(formik, "sites", formik.values.sites.length)
          }
        >
          Add sites
        </Button>
      )}
    </>
  );
};

export { SitesRepeater };
