import { Grid, Toolbar } from "@mui/material";

interface NewCampaignProps {}

const NewCampaign: React.FC<NewCampaignProps> = () => {
  return (
    <Grid container>
      <Toolbar />
      <Toolbar />
      <Toolbar />
      <Toolbar />
      <div>NewCampaign</div>
    </Grid>
  );
};

export default NewCampaign;
