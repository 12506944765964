import {
  Button,
  Card,
  CardHeader,
  Chip,
  Grid,
  Toolbar,
  Typography,
} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../axios";
import { PlatformProps } from "./Platforms";
import PlatformSingleForm from "./PlatformSingleForm";

interface PlatformSingleProps {
  platform?: PlatformProps;
}

const PlatformSingle: React.FC<PlatformSingleProps> = ({ platform }) => {
  const [uiPlatform, setUiPlatform] = useState<PlatformProps | undefined>(
    platform
  );
  const { platformId } = useParams<{ platformId: string }>();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  useEffect(() => {
    if (!platform && platformId !== "new-platform") {
      axiosInstance
        .get(`/platforms/${platformId}`)
        .then(function (response: any) {
          console.log(response);

          setUiPlatform(response.data[0]);
        })
        .catch(function (error: any) {
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    }
  }, []);

  return (
    <>
      <Box
        className="subheader"
        sx={{
          position: "sticky",
          width: "100%",
          top: 0,
          bgcolor: "background.paper",
          zIndex: 1,
        }}
      >
        <Toolbar />
        <Toolbar>
          {uiPlatform && (
            <Typography variant="h6">{uiPlatform?.name}</Typography>
          )}
          {uiPlatform && <Chip label={uiPlatform?.version} sx={{ ml: 1.5 }} />}
          <Button
            color="primary"
            onClick={() => {
              setIsEditMode(!isEditMode);
            }}
            variant={isEditMode ? "contained" : "text"}
            sx={{ marginLeft: "auto" }}
          >
            {isEditMode ? `Cancel` : `Edit`}
          </Button>
        </Toolbar>
      </Box>
      {isEditMode ? (
        <PlatformSingleForm platform={uiPlatform} />
      ) : (
        <PlatformSingleView platform={uiPlatform} />
      )}
      {/* {isEditMode && (
        <IonFooter>
          <IonToolbar>
            <IonButtons>
              <IonButton color="primary" fill="solid">
                Save
              </IonButton>
            </IonButtons>
            <IonTitle>Select platforms to perform bulk edit.</IonTitle>
          </IonToolbar>
        </IonFooter>
      )} */}
    </>
  );
};

export default PlatformSingle;

interface PlatformSingleViewProps {
  platform?: PlatformProps;
}

const PlatformSingleView: React.FC<PlatformSingleViewProps> = ({
  platform,
}) => {
  const formatDate = (date: string) => {
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(Number.parseInt(date));
  };
  if (!platform) {
    return <>Loading...</>;
  }
  return (
    <Box sx={{ padding: "0 24px" }}>
      <Grid container spacing={3}>
        <Grid item md={12} lg={6}>
          <Card sx={{ minWidth: 275 }} variant="outlined">
            <CardHeader title="sites" />
            <CardContent>
              {platform?.sites?.map((site, index) => (
                <React.Fragment key={index}>
                  <Typography variant="body1" component="div">
                    {site}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    site {index + 1}
                  </Typography>
                </React.Fragment>
              ))}
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={12} lg={6}>
          <Card sx={{ minWidth: 275 }} variant="outlined">
            <CardHeader title="about" />
            <CardContent>
              {platform?.id && (
                <>
                  <Typography variant="body1" component="div">
                    {platform?.id}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    id
                  </Typography>
                </>
              )}
              {platform?.csm && (
                <>
                  <Typography variant="body1" component="div">
                    {platform?.csm}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    lead csm
                  </Typography>
                </>
              )}
              {platform?.date_created && (
                <>
                  <Typography variant="body1" component="div">
                    {formatDate(platform?.date_created)}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    date_created
                  </Typography>
                </>
              )}
              {platform?.date_modified && (
                <>
                  <Typography variant="body1" component="div">
                    {formatDate(platform?.date_modified)}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    date_modified
                  </Typography>
                </>
              )}
              {platform?.version && (
                <>
                  <Typography variant="body1" component="div">
                    {platform?.version}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    version
                  </Typography>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={12} lg={6}>
          <Card sx={{ minWidth: 275 }} variant="outlined">
            <CardHeader title="database" />
            <CardContent>
              {platform?.db_host && (
                <>
                  <Typography variant="body1" component="div">
                    {platform?.db_host}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    db_host
                  </Typography>
                </>
              )}
              {platform?.db_name && (
                <>
                  <Typography variant="body1" component="div">
                    {platform?.db_name}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    db_name
                  </Typography>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={12} lg={6}>
          <Card sx={{ minWidth: 275 }} variant="outlined">
            <CardHeader title="slack" />
            <CardContent>
              {platform?.slack_channel_name && (
                <>
                  <Typography variant="body1" component="div">
                    {platform?.slack_channel_name}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    slack channel name
                  </Typography>
                </>
              )}
              {platform?.slack_channel_topic && (
                <>
                  <Typography variant="body1" component="div">
                    {platform?.slack_channel_topic}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    slack channel topic
                  </Typography>
                </>
              )}
              {platform?.slack_channel_description && (
                <>
                  <Typography variant="body1" component="div">
                    {platform?.slack_channel_description}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    slack channel description
                  </Typography>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={12} lg={6}>
          <Card sx={{ minWidth: 275 }} variant="outlined">
            <CardHeader title="dev ops" />
            <CardContent>
              {platform?.scale && (
                <>
                  <Typography variant="body1" component="div">
                    {platform?.scale}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    scale
                  </Typography>
                </>
              )}

              {platform?.user_pool_id && (
                <>
                  <Typography variant="body1" component="div">
                    {platform?.user_pool_id}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    user_pool_id
                  </Typography>
                </>
              )}

              {platform?.cognito_user_pool_domain && (
                <>
                  <Typography variant="body1" component="div">
                    {platform?.cognito_user_pool_domain}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    cognito_user_pool_domain
                  </Typography>
                </>
              )}

              {platform?.cognito_client_id && (
                <>
                  <Typography variant="body1" component="div">
                    {platform?.cognito_client_id}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    cognito_client_id
                  </Typography>
                </>
              )}

              {platform?.s3_bucket_name && (
                <>
                  <Typography variant="body1" component="div">
                    {platform?.s3_bucket_name}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    s3_bucket_name
                  </Typography>
                </>
              )}

              {platform?.s3_bucket_url && (
                <>
                  <Typography variant="body1" component="div">
                    {platform?.s3_bucket_url}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    s3_bucket_url
                  </Typography>
                </>
              )}

              {platform?.memcache_url && (
                <>
                  <Typography variant="body1" component="div">
                    {platform?.memcache_url}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    memcache_url
                  </Typography>
                </>
              )}

              {platform?.remote_json_bucket && (
                <>
                  <Typography variant="body1" component="div">
                    {platform?.remote_json_bucket}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    remote_json_bucket
                  </Typography>
                </>
              )}

              {platform?.remote_json_url && (
                <>
                  <Typography variant="body1" component="div">
                    {platform?.remote_json_url}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    remote_json_url
                  </Typography>
                </>
              )}

              {platform?.use_memcache && (
                <>
                  <Typography variant="body1" component="div">
                    {platform?.use_memcache.toString()}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    use_memcache
                  </Typography>
                </>
              )}

              {platform?.cloudfront_dist_id && (
                <>
                  <Typography variant="body1" component="div">
                    {platform?.cloudfront_dist_id}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    cloudfront_dist_id
                  </Typography>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={12} lg={6}>
          <Card sx={{ minWidth: 275 }} variant="outlined">
            <CardHeader title="actions" />
            <CardContent>
              <Button
                href={`https://${platform?.sites}/custom_api/migrations/api_migrate.php?KEY=AKIAQDIFOJERGVGPK4CM`}
                target="_blank"
              >
                Run Migrations
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* {platform?.hostnames.map((hostname, index) => {
              return (
                <IonItem key={index}>
                  <IonLabel>{hostname}</IonLabel>
                </IonItem>
              );
            })} */}
    </Box>
  );
};
