import { object, string } from "yup";
interface ValidationProps {
  handleErrors: (newErrors: any) => void;
  data: any;
}

const sessionSchema = object({
  id: string().required(),
  name: string().required(),
  slug: string().required(),
});

const Validation: React.FC<ValidationProps> = ({ handleErrors, data }) => {
  console.log(data.map((row: any) => sessionSchema.validate(row)));
  return <></>;
};

export default Validation;
