import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Toolbar,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as yup from "yup"; // .... nope... 😀
import { SitesRepeater } from "./InputRepeater";
import VersionSelect from "./VersionSelect";

import { PlatformProps } from "./Platforms";
import {
  PlatformDto,
  PlatformsService,
  PlatformVersionDto,
  VersionsService,
} from "../generated";
import { useEffect, useState } from "react";

interface PlatformSingleFormProps {
  platform?: PlatformProps;
}

const validationSchema = yup.object().shape({
  id: yup.string().required("Id is required"),
  name: yup
    .string()
    .required("Name is required")
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^[a-z\-]+$/,
      "Name must contain only lowercase letters and underscores, no uppercase, numbers or spaces"
    )
    .trim(),
  sites: yup.array().of(
    yup.string().required("Site is required")
    //   .matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, "Site must be a valid slug")
  ),
  vanityUrls: yup.array().of(yup.string()),
  slack_channel_name: yup.string(),
  slack_channel_topic: yup.string(),
  slack_channel_description: yup.string(),
  csm: yup.string(),
  version: yup.string(),
  scale: yup.string(),
  user_pool_id: yup.string(),
  cognito_user_pool_domain: yup.string(),
  cognito_client_id: yup.string(),
  db_host: yup.string(),
  db_name: yup.string(),
  s3_bucket_name: yup.string(),
  s3_bucket_url: yup.string(),
  memServer1: yup.string(),
  remote_json_bucket: yup.string(),
  remote_json_url: yup.string(),
  use_memcache: yup.boolean(),
});

const PlatformSingleForm: React.FC<PlatformSingleFormProps> = ({
  platform,
}) => {
  const navigate = useNavigate();

  const initialValues = {
    s3_bucket_name: platform?.s3_bucket_name || "",
    s3_bucket_url: platform?.s3_bucket_url || "",
    cloudfront_dist_id: platform?.cloudfront_dist_id || "",
    cognito_client_id: platform?.cognito_client_id || "",
    cognito_user_pool_domain: platform?.cognito_user_pool_domain || "",
    csm: platform?.csm || "",
    db_host: platform?.db_host || "",
    db_name: platform?.db_name || "",
    sites: platform?.sites || [""],
    id: platform?.id || "",
    memServer1: platform?.memcache_url || "",
    name: platform?.name || "",
    remote_json_bucket: platform?.remote_json_bucket || "",
    remote_json_url: platform?.remote_json_url || "",
    scale: platform?.scale || "",
    slack_channel_description: platform?.slack_channel_description || "",
    slack_channel_name: platform?.slack_channel_name || "",
    slack_channel_topic: platform?.slack_channel_topic || "",
    use_memcache: platform?.use_memcache || false,
    user_pool_id: platform?.user_pool_id || "",
    version: platform?.version || "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async () => {
      await handlePost();
      navigate(`/platforms`);
    },
  });

  const handlePost = async () => {
    const platform = {
      id: formik.values.id.trim(),
      sites: formik.values.sites,
      name: formik.values.name.trim(),
      csm: formik.values.csm.trim(),
      slack_channel_name: formik.values.slack_channel_name.trim(),
      slack_channel_topic: formik.values.slack_channel_topic.trim(),
      slack_channel_description: formik.values.slack_channel_description.trim(),
      version: formik.values.version.trim(),
      scale: formik.values.scale.trim(),
      user_pool_id: formik.values.user_pool_id.trim(),
      cognito_user_pool_domain: formik.values.cognito_user_pool_domain.trim(),
      cognito_client_id: formik.values.cognito_client_id.trim(),
      db_host: formik.values.db_host.trim(),
      db_name: formik.values.db_name.trim(),
      s3_bucket_name: formik.values.s3_bucket_name.trim(),
      s3_bucket_url: formik.values.s3_bucket_url.trim(),
      memServer1: formik.values.memServer1.trim(),
      remote_json_bucket: formik.values.remote_json_bucket.trim(),
      remote_json_url: formik.values.remote_json_url.trim(),
      use_memcache: formik.values.use_memcache,
      cloudfront_dist_id: formik.values.cloudfront_dist_id.trim(),
    } as PlatformDto;

    PlatformsService.platformCreatePlatformsPost(platform);
  };

  const [versions, setVersions] = useState<PlatformVersionDto[]>();
  useEffect(() => {
    VersionsService.infraListVersionsInfraVersionsGet().then((versions) => {
      setVersions(versions);
    });
  }, []);

  const handleVersionChange = (version?: any) => {
    formik.setFieldValue("version", version.target.value);
  };

  return (
    <Box sx={{ padding: "0 24px" }}>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          name="id"
          value={formik.values.id}
          onChange={formik.handleChange}
          error={formik.touched.id && Boolean(formik.errors.id)}
          helperText={formik.touched.id && formik.errors.id}
          label="id"
          variant="standard"
          inputProps={{
            onBlur: () => formik.setFieldTouched("id", true, true),
          }}
        />

        <TextField
          fullWidth
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          label="name"
          variant="standard"
          inputProps={{
            onBlur: () => formik.setFieldTouched("name", true, true),
          }}
        />

        <SitesRepeater formik={formik} sortKeys={platform?.sites} />

        <TextField
          fullWidth
          name="csm"
          value={formik.values.csm}
          onChange={formik.handleChange}
          error={formik.touched.csm && Boolean(formik.errors.csm)}
          helperText={formik.touched.csm && formik.errors.csm}
          label="Lead CSM"
          variant="standard"
          inputProps={{
            onBlur: () => formik.setFieldTouched("csm", true, true),
          }}
        />

        <TextField
          fullWidth
          name="slack_channel_name"
          value={formik.values.slack_channel_name}
          onChange={formik.handleChange}
          error={
            formik.touched.slack_channel_name &&
            Boolean(formik.errors.slack_channel_name)
          }
          helperText={
            formik.touched.slack_channel_name &&
            formik.errors.slack_channel_name
          }
          label="slack_channel_name"
          variant="standard"
          inputProps={{
            onBlur: () =>
              formik.setFieldTouched("slack_channel_name", true, true),
          }}
        />

        <TextField
          fullWidth
          name="slack_channel_topic"
          value={formik.values.slack_channel_topic}
          onChange={formik.handleChange}
          error={
            formik.touched.slack_channel_topic &&
            Boolean(formik.errors.slack_channel_topic)
          }
          helperText={
            formik.touched.slack_channel_topic &&
            formik.errors.slack_channel_topic
          }
          label="slack_channel_topic"
          variant="standard"
          inputProps={{
            onBlur: () =>
              formik.setFieldTouched("slack_channel_topic", true, true),
          }}
        />

        <TextField
          fullWidth
          name="slack_channel_description"
          value={formik.values.slack_channel_description}
          onChange={formik.handleChange}
          error={
            formik.touched.slack_channel_description &&
            Boolean(formik.errors.slack_channel_description)
          }
          helperText={
            formik.touched.slack_channel_description &&
            formik.errors.slack_channel_description
          }
          label="slack_channel_description"
          variant="standard"
          inputProps={{
            onBlur: () =>
              formik.setFieldTouched("slack_channel_description", true, true),
          }}
        />

        <VersionSelect versions={versions} onChange={handleVersionChange} />

        <TextField
          fullWidth
          name="scale"
          value={formik.values.scale}
          onChange={formik.handleChange}
          error={formik.touched.scale && Boolean(formik.errors.scale)}
          helperText={formik.touched.scale && formik.errors.scale}
          label="scale"
          variant="standard"
          inputProps={{
            onBlur: () => formik.setFieldTouched("scale", true, true),
          }}
        />

        <TextField
          fullWidth
          name="user_pool_id"
          value={formik.values.user_pool_id}
          onChange={formik.handleChange}
          error={
            formik.touched.user_pool_id && Boolean(formik.errors.user_pool_id)
          }
          helperText={formik.touched.user_pool_id && formik.errors.user_pool_id}
          label="user_pool_id"
          variant="standard"
          inputProps={{
            onBlur: () => formik.setFieldTouched("user_pool_id", true, true),
          }}
        />

        <TextField
          fullWidth
          name="cognito_user_pool_domain"
          value={formik.values.cognito_user_pool_domain}
          onChange={formik.handleChange}
          error={
            formik.touched.cognito_user_pool_domain &&
            Boolean(formik.errors.cognito_user_pool_domain)
          }
          helperText={
            formik.touched.cognito_user_pool_domain &&
            formik.errors.cognito_user_pool_domain
          }
          label="cognito_user_pool_domain"
          variant="standard"
          inputProps={{
            onBlur: () =>
              formik.setFieldTouched("cognito_user_pool_domain", true, true),
          }}
        />

        <TextField
          fullWidth
          name="cognito_client_id"
          value={formik.values.cognito_client_id}
          onChange={formik.handleChange}
          error={
            formik.touched.cognito_client_id &&
            Boolean(formik.errors.cognito_client_id)
          }
          helperText={
            formik.touched.cognito_client_id && formik.errors.cognito_client_id
          }
          label="cognito_client_id"
          variant="standard"
          inputProps={{
            onBlur: () =>
              formik.setFieldTouched("cognito_client_id", true, true),
          }}
        />

        <TextField
          fullWidth
          name="db_host"
          value={formik.values.db_host}
          onChange={formik.handleChange}
          error={formik.touched.db_host && Boolean(formik.errors.db_host)}
          helperText={formik.touched.db_host && formik.errors.db_host}
          label="db_host"
          variant="standard"
          inputProps={{
            onBlur: () => formik.setFieldTouched("db_host", true, true),
          }}
        />

        <TextField
          fullWidth
          name="db_name"
          value={formik.values.db_name}
          onChange={formik.handleChange}
          error={formik.touched.db_name && Boolean(formik.errors.db_name)}
          helperText={formik.touched.db_name && formik.errors.db_name}
          label="db_name"
          variant="standard"
          inputProps={{
            onBlur: () => formik.setFieldTouched("db_name", true, true),
          }}
        />

        <TextField
          fullWidth
          name="s3_bucket_name"
          value={formik.values.s3_bucket_name}
          onChange={formik.handleChange}
          error={
            formik.touched.s3_bucket_name &&
            Boolean(formik.errors.s3_bucket_name)
          }
          helperText={
            formik.touched.s3_bucket_name && formik.errors.s3_bucket_name
          }
          label="s3_bucket_name"
          variant="standard"
          inputProps={{
            onBlur: () => formik.setFieldTouched("s3_bucket_name", true, true),
          }}
        />

        <TextField
          fullWidth
          name="s3_bucket_url"
          value={formik.values.s3_bucket_url}
          onChange={formik.handleChange}
          error={
            formik.touched.s3_bucket_url && Boolean(formik.errors.s3_bucket_url)
          }
          helperText={
            formik.touched.s3_bucket_url && formik.errors.s3_bucket_url
          }
          label="s3_bucket_url"
          variant="standard"
          inputProps={{
            onBlur: () => formik.setFieldTouched("s3_bucket_url", true, true),
          }}
        />

        <TextField
          fullWidth
          name="memServer1"
          value={formik.values.memServer1}
          onChange={formik.handleChange}
          error={formik.touched.memServer1 && Boolean(formik.errors.memServer1)}
          helperText={formik.touched.memServer1 && formik.errors.memServer1}
          label="memServer1"
          variant="standard"
          inputProps={{
            onBlur: () => formik.setFieldTouched("memServer1", true, true),
          }}
        />

        <TextField
          fullWidth
          name="remote_json_bucket"
          value={formik.values.remote_json_bucket}
          onChange={formik.handleChange}
          error={
            formik.touched.remote_json_bucket &&
            Boolean(formik.errors.remote_json_bucket)
          }
          helperText={
            formik.touched.remote_json_bucket &&
            formik.errors.remote_json_bucket
          }
          label="remote_json_bucket"
          variant="standard"
          inputProps={{
            onBlur: () =>
              formik.setFieldTouched("remote_json_bucket", true, true),
          }}
        />

        <TextField
          fullWidth
          name="remote_json_url"
          value={formik.values.remote_json_url}
          onChange={formik.handleChange}
          error={
            formik.touched.remote_json_url &&
            Boolean(formik.errors.remote_json_url)
          }
          helperText={
            formik.touched.remote_json_url && formik.errors.remote_json_url
          }
          label="remote_json_url"
          variant="standard"
          inputProps={{
            onBlur: () => formik.setFieldTouched("remote_json_url", true, true),
          }}
        />

        <TextField
          fullWidth
          name="cloudfront_dist_id"
          value={formik.values.cloudfront_dist_id}
          onChange={formik.handleChange}
          error={
            formik.touched.cloudfront_dist_id &&
            Boolean(formik.errors.cloudfront_dist_id)
          }
          helperText={
            formik.touched.cloudfront_dist_id &&
            formik.errors.cloudfront_dist_id
          }
          label="cloudfront_dist_id"
          variant="standard"
          inputProps={{
            onBlur: () =>
              formik.setFieldTouched("cloudfront_dist_id", true, true),
          }}
        />

        <FormControl fullWidth>
          <InputLabel id="use_memcache">use_memcache</InputLabel>
          <Select
            labelId="use_memcache"
            id="demo-simple-select"
            name="use_memcache"
            value={formik.values.use_memcache}
            label="use_memcache"
            onChange={formik.handleChange}
          >
            <MenuItem value="true">True</MenuItem>
            <MenuItem value="false">False</MenuItem>
          </Select>
        </FormControl>

        {platform ? (
          <Button type="submit">Update</Button>
        ) : (
          <Button type="submit">Spin up new platform</Button>
        )}
      </form>
    </Box>
  );
};

export default PlatformSingleForm;

interface PlatformSingleNewProps {}

export const PlatformSingleNew: React.FC<PlatformSingleNewProps> = () => {
  let navigate = useNavigate();
  return (
    <>
      <>
        <Toolbar />
        <TextField
          id="outlined-basic"
          label="New Platform Name"
          variant="outlined"
        />
        <Typography>Create Platform</Typography>
        <Button onClick={() => navigate(`/platforms`)}>Cancel</Button>
      </>
      <PlatformSingleForm />
    </>
  );
};
