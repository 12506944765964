import { Button, Stack, Toolbar } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface CampaignListFooterProps {}

const CampaignListFooter: React.FC<CampaignListFooterProps> = () => {
  let navigate = useNavigate();
  const handleNewCampaignClick = () => {
    navigate(`new-campaign`);
  };

  return (
    <Stack
      direction="row"
      sx={{
        position: "sticky",
        bottom: 0,
        opacity: 1,
        justifyContent: "space-between",
        bgcolor: "background.paper",
        zIndex: 1,
      }}
    >
      <Toolbar>
        <Button
          onClick={() => {
            handleNewCampaignClick();
          }}
        >
          New Campaign
        </Button>
      </Toolbar>
    </Stack>
  );
};

export default CampaignListFooter;
