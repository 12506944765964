import { Box, Chip, Grid, ListItem, Radio, Typography } from "@mui/material";
import { PlatformProps } from "./Platforms";
interface PlatformItemProps {
  platform: PlatformProps;
  handlePlatformClick: (platform: PlatformProps) => void;
  isEditMode: boolean;
  toggleSelected: (platform: PlatformProps) => void;
}

const PlatformItem: React.FC<PlatformItemProps> = ({
  platform,
  handlePlatformClick,
  isEditMode,
  toggleSelected,
  children,
}) => {
  return (
    <ListItem
      button
      component="a"
      onClick={
        isEditMode
          ? () => {
              toggleSelected(platform);
            }
          : () => handlePlatformClick(platform)
      }
    >
      <Grid container sx={{ padding: "8px 24px" }}>
        <Grid item sx={{ display: "flex" }}>
          {isEditMode && (
            <Radio
              checked={platform.isSelected}
              value="a"
              name="radio-buttons"
              inputProps={{ "aria-label": "A" }}
            />
          )}
        </Grid>
        <Box>
          <Typography variant="h6">{platform?.name?.toLowerCase()}</Typography>

          <Typography>{platform?.sites}</Typography>

          <Typography color="medium">{platform?.csm}</Typography>
        </Box>
        <Box sx={{ marginLeft: "auto" }}>
          {platform?.version && <Chip label={platform?.version} />}
        </Box>
      </Grid>
      {children}
    </ListItem>
  );
};
export default PlatformItem;
