import { Grid, Toolbar } from "@mui/material";
import { useEffect, useState } from "react";
import { EmailProps } from "../models/email";
import { PlatformProps } from "../platforms/Platforms";
import { EmailForm } from "./EmailForm";
import axiosInstance from "../axios";

interface CampaignProps {
  campaign?: EmailProps;
  client?: PlatformProps;
}

const Campaign: React.FC<CampaignProps> = ({ campaign, client }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [uiCampaign, setUiCampaign] = useState<EmailProps | undefined>(
    campaign
  );
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (!campaign) {
      axiosInstance
        .get(`/platforms`)
        .then(function (response: any) {
          // handle success
          console.log(response);
          setUiCampaign(response);
          console.log(`Platform: ${JSON.stringify(response, null, 4)}`);
        })
        .catch(function (error: any) {
          // handle error
          console.log(error);
          setIsError(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign]);

  if (isError)
    <>
      <div>Error loading</div>
    </>;
  return (
    <>
      <Toolbar />
      {/* <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle slot="start">{campaign?.campaign_name}</IonTitle>
          <IonTitle slot="end"> {client?.name}</IonTitle>
        </IonToolbar>
      </IonHeader> */}
      <Grid container>
        <EmailForm campaign={campaign} />
      </Grid>
    </>
  );
};

export default Campaign;
