//TODO Skull do you need temp data? Model it here or else delete it
//TODO make sure you model it in email.d.ts
export const tempdata = [
  {
    id: "1",
    name: "Welcome Email",
    active: true,
    override: false,
  },
  {
    id: "2",
    name: "Mobile Verification",
    active: true,
    override: false,
  },
  {
    id: "3",
    name: "Another Type of Email",
    active: true,
    override: false,
  },
  {
    id: "4",
    name: "Blah Blah Blah",
    active: false,
    override: false,
  },
  {
    id: "5",
    name: "Blah Blah Blah",
    active: false,
    override: false,
  },
];

export const users = [
  {
    id: "1",
    name: "John Doe",
    email: "johndoe@me.com",
    status: {
      sent: true,
      opened: false,
    },
    tags: [
      {
        id: "1",
        name: "Tag 1",
      },
      {
        id: "2",
        name: "Tag 2",
      },
    ],
  },
  {
    id: "2",
    name: "Jane Doe",
    email: "janedoe@me.com",
    status: {
      sent: false,
      opened: false,
    },
    tags: [
      {
        id: "1",
        name: "Tag 1",
      },
      {
        id: "2",
        name: "Tag 2",
      },
      {
        id: "3",
        name: "Tag 3",
      },
    ],
  },
  {
    id: "3",
    name: "John Smith",
    email: "johnsmith@me.com",
    status: {
      sent: true,
      opened: false,
    },
  },
  {
    id: "4",
    name: "Jane Smith",
    email: "janesmith@live.com",
    status: {
      sent: true,
      opened: true,
    },
  },
  {
    id: "5",
    name: "Mary Sue",
    email: "marysue@gmail.com",
    status: {
      sent: true,
      opened: false,
    },
    tags: [
      {
        id: "1",
        name: "Tag 1",
      },
      {
        id: "2",
        name: "Tag 2",
      },
      {
        id: "3",
        name: "Tag 3",
      },

      {
        id: "4",
        name: "Tag 4",
      },
      {
        id: "5",
        name: "Tag 5",
      },
      {
        id: "6",
        name: "Tag 6",
      },
    ],
  },
  {
    id: "6",
    name: "Jim Bob",
    email: "jimbob@hotmail.com",
    status: {
      sent: true,
      opened: true,
    },
    tags: [
      {
        id: "1",
        name: "Tag 1",
      },
      {
        id: "2",
        name: "Tag 2",
      },
    ],
  },
  {
    id: "7",
    name: "Jessica Brentley",
    email: "jb@microsoft.com",
    status: {
      sent: false,
      opened: false,
    },
    tags: [
      {
        id: "1",
        name: "Tag 1",
      },
      {
        id: "2",
        name: "Tag 2",
      },
    ],
  },
  {
    id: "8",
    name: "Patrick Smalley",
    email: "psmalley@blah.com",
    status: {
      sent: true,
      opened: false,
    },
    tags: [
      {
        id: "1",
        name: "Tag 1",
      },
      {
        id: "2",
        name: "Tag 2",
      },
    ],
  },
];
