import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { PlatformProps } from "./Platforms";
import PlatformItem from "./PlatformItem";
import PlatformListFooter from "./PlatformListFooter";
import PlatformBulkActionsFooter from "./PlatformBulkActionsFooter";
import List from "@mui/material/List";
import Skeleton from "../components/Skeleton";

interface PlatformListProps {
  platforms?: PlatformProps[];
  handlePlatformClick: (platform: PlatformProps) => void;
}

const PlatformList: React.FC<PlatformListProps> = ({
  platforms,
  handlePlatformClick,
}) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [filteredPlatforms, setFilteredPlatforms] = useState<PlatformProps[]>();

  //update search state when search text changes
  useEffect(() => {
    if (platforms) {
      const tempFilteredPlatforms: PlatformProps[] = platforms?.filter(
        (platform) => {
          return platform.name
            ?.toLowerCase()
            .includes(searchText.toLowerCase());
        }
      );
      setFilteredPlatforms(tempFilteredPlatforms);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  //init platforms with state for bulk edit
  useEffect(() => {
    if (platforms) {
      const initPlatformArray = platforms.filter(
        (platform) => platform.isSelected || !platform.isSelected
      );
      setFilteredPlatforms(initPlatformArray);
    }
  }, [platforms]);

  const toggleSelected = (platform: PlatformProps) => {
    const tempPlatformArray = filteredPlatforms?.map((p) => {
      if (platform.id === p.id) {
        return { ...p, isSelected: !p.isSelected };
      } else {
        return p;
      }
    });
    setFilteredPlatforms(tempPlatformArray);
  };

  return (
    <Box>
      <Box
        className="subheader"
        sx={{
          position: "sticky",
          width: "100%",
          top: 0,
          bgcolor: "background.paper",
          zIndex: 1,
        }}
      >
        <Toolbar />
        <Toolbar>
          <Typography variant="h6">Platforms</Typography>
          <Button
            color="primary"
            onClick={() => {
              setIsEditMode(!isEditMode);
            }}
            variant={isEditMode ? "contained" : "text"}
            sx={{ marginLeft: "auto" }}
          >
            Edit
          </Button>
        </Toolbar>
        <Toolbar>
          <TextField
            fullWidth
            value={searchText}
            placeholder="Search"
            onChange={(e) => setSearchText(e.target.value!)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Toolbar>
      </Box>
      <List sx={{ width: "100%", flex: "1" }}>
        {platforms ? (
          filteredPlatforms?.map((platform, index) => (
            <PlatformItem
              key={index}
              platform={platform}
              handlePlatformClick={handlePlatformClick}
              isEditMode={isEditMode}
              toggleSelected={toggleSelected}
            />
          ))
        ) : (
          <Skeleton loop={10} variant="platforms" />
        )}
      </List>

      {isEditMode ? (
        filteredPlatforms?.some((platform) => platform.isSelected) && (
          <PlatformBulkActionsFooter
            selected={filteredPlatforms?.filter((p) => p.isSelected === true)}
          />
        )
      ) : (
        <PlatformListFooter count={filteredPlatforms?.length} />
      )}
    </Box>
  );
};

export default PlatformList;
