import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React from "react";
import { PlatformProps } from "../platforms/Platforms";
import axiosInstance from "../axios";

interface ClientSelectorProps {}

const ClientSelector: React.FC<ClientSelectorProps> = () => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<PlatformProps[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [value, setValue] = React.useState<PlatformProps[]>([]);
  const loading = open && options.length === 0;

  //get tags from API
  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    axiosInstance
      .get(`/platforms`)
      .then(function (response: any) {
        console.log(response);
        console.log(`GET: ${JSON.stringify(response, null, 4)}`);
        if (active) {
          setOptions(response.data);
        }
      })
      .catch(function (error: any) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });

    return () => {
      active = false;
    };
  }, [loading]);

  return (
    <Autocomplete
      fullWidth
      //   value={value}
      //   onChange={(event: any, newValue: string[] | null) => {
      //     setValue(newValue as PlatformProps[]);
      //   }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      loading={loading}
      options={options}
      getOptionLabel={(option) => option.id}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={"client"}
          placeholder="filter by tags"
          name={"client"}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
};

export default ClientSelector;
