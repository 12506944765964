import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Box, Button } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";
import { EmailProps } from "../models/email";
import ControlledAutocomplete from "./ControlledAutocomplete";
import DateTimePicker from "./DateTimePicker";
import DragDropEmail from "./DragDropEmail";
import OutboundModal from "./OutboundModal";
import Tags from "./TagFilter";

interface EmailFormProps {
  campaign?: EmailProps;
}

const validationSchema = yup.object().shape({
  id: yup.string(),
  name: yup.string(),
  status: yup.boolean(),
  to: yup.array(),
  from: yup.string().email("Enter a valid email"),
  subject: yup.string().max(998, "Subject must be less than 998 characters"),
  start_date: yup.date().required("start date is required"),
  end_date: yup.date().required("end date is required"),
  template: yup.object(),
  active: yup.boolean(),
  override: yup.boolean(),
});

export const EmailForm: React.FC<EmailFormProps> = ({ campaign }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const initialValues = {
    // id: campaign?.id || "",
    name: campaign?.campaign_name || "",
    to: campaign?.tags || [],
    from: campaign?.from_address || null,
    subject: campaign?.subject || "",
    start_date: campaign?.start_date || new Date(),
    end_date: campaign?.end_date || new Date(),
    template: campaign?.template_name || null,
    active: campaign?.active || false,
    override: campaign?.override || false,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      //   handlePut();
      //   history.push(`/campaigns`);
      console.log(JSON.stringify(values, null, 4));
    },
  });

  return (
    <Box sx={{ padding: "24px", margin: "auto" }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            label="Campaign Name"
            variant="standard"
            inputProps={{
              onBlur: () => formik.setFieldTouched("name", true, true),
            }}
          />

          <Tags formik={formik} name="to" />

          <ControlledAutocomplete formik={formik} name="from" label="From" />

          <TextField
            fullWidth
            name="subject"
            value={formik.values.subject}
            onChange={formik.handleChange}
            error={formik.touched.subject && Boolean(formik.errors.subject)}
            helperText={formik.touched.subject && formik.errors.subject}
            label="Subject"
            variant="standard"
            inputProps={{
              onBlur: () => formik.setFieldTouched("subject", true, true),
            }}
          />

          <ControlledAutocomplete
            formik={formik}
            name="template"
            label="Mandrill Template"
          />

          <DateTimePicker
            formik={formik}
            name="start_date"
            disabled={formik.values.override}
          />

          <DateTimePicker
            formik={formik}
            name="end_date"
            disabled={formik.values.override}
          />
          <FormControlLabel
            control={
              <Switch
                checked={formik.values.override}
                onChange={formik.handleChange}
                inputProps={{ "aria-label": "controlled" }}
                color="success"
                name="override"
              />
            }
            label="override"
          />

          <FormControlLabel
            control={
              <Switch
                checked={formik.values.active}
                onChange={formik.handleChange}
                inputProps={{ "aria-label": "controlled" }}
                color="success"
                name="active"
              />
            }
            label="active"
          />

          <DragDropEmail />
          <Button onClick={handleOpen}>View Outbound</Button>
          <OutboundModal
            open={open}
            onClose={handleClose}
            selectedValue="test"
          />
          <Button type="button">Send Test</Button>
          <Button type="submit">Save Campaign</Button>
        </form>
      </LocalizationProvider>
    </Box>
  );
};
